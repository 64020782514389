<template>
  <v-row style="height:100%">
    <v-col cols=3 class="py-0">
      <v-list-item class="text-h6 align-center"
        :to="'/report/' + $route.params.extension_id + '/' + $route.params.version + '?platform=' + ($route.query.platform || 'Chrome')">
        <v-icon>arrow_back</v-icon>Back to Report
      </v-list-item>
      <v-divider/>
      <v-row v-if="loading" align='center' justify='center' class='fill-height'>
        <v-progress-circular indeterminate color="primary" size=75 />
      </v-row>
      <v-list style="max-height:calc(100vh - 48px);overflow:scroll">
        <v-list-item v-for="(file, index) in files" :key="index" @click="setCurrentFile(file)">
          {{ file }}
        </v-list-item>
      </v-list>
    </v-col>
    <v-col cols=9 class="py-0 px-0">
      <editor ref='myEditor' @init="editorInit" lang="javascript" theme="solarized_light"></editor>
    </v-col>
  </v-row>
</template>

<script>
import jszip from 'jszip'
import beautify from 'js-beautify'

export default {
  title: 'CRXcavator',
  name: 'ViewSource',
  components: {
    editor: require('vue2-ace-editor')
  },
  data () {
    return {
      data: null,
      files: [],
      fileContents: '',
      loading: true,
      currentFile: '',
      currentLine: '',
      mode: 'javascript',
      shouldBeautify: true
    }
  },
  methods: {
    bruh () {
      console.log(this.$route)
    },
    editorInit: function (editor) {
      require('brace/ext/language_tools')
      require('brace/mode/html')
      require('brace/mode/javascript')
      require('brace/mode/jsx')
      require('brace/mode/json')
      require('brace/mode/css')
      require('brace/theme/solarized_light')
      require('brace/snippets/javascript')
      require('brace/ext/searchbox')
      console.log(editor)
      editor.setOptions({
        readOnly: true,
        name: 'myEditor',
        height: 'calc(100vh - 48px)',
        width: 'auto',
        fontSize: 14,
        showPrintMargin: true,
        showGutter: true,
        cursorStart: 1,
        focus: true
      })
    },
    getExtension (extensionId, version, platform) {
      // TODO allow for non-chrome extensions
      let url = ''

      if (!this.$route.query.platform) {
        url = `https://extensions.crxcavator.io/${extensionId}_${version}.zip`
        this.$router.push({ path: `/source/${this.$route.params.extension_id}/${this.$route.params.version}`, query: { file: this.$route.query.filename, platform: 'Chrome' } })
      } else {
        switch (this.$route.query.platform.toLowerCase()) {
          case 'chrome':
            url = `https://extensions.crxcavator.io/${extensionId}_${version}.zip`
            this.$router.push({ path: `/source/${this.$route.params.extension_id}/${this.$route.params.version}`, query: { file: this.$route.query.filename, platform: 'Chrome' } })
            break
          case 'edge':
            url = `https://extensions.crxcavator.io/edge/${extensionId}_${version}.zip`
            this.$router.push({ path: `/source/${this.$route.params.extension_id}/${this.$route.params.version}`, query: { file: this.$route.query.filename, platform: 'Edge' } })
            break
          case 'firefox':
            url = `https://extensions.crxcavator.io/firefox/${extensionId}_${version}.zip`
            this.$router.push({ path: `/source/${this.$route.params.extension_id}/${this.$route.params.version}`, query: { file: this.$route.query.filename, platform: 'Firefox' } })
            break
          default:
            url = `https://extensions.crxcavator.io/${extensionId}_${version}.zip`
            this.$router.push({ path: `/source/${this.$route.params.extension_id}/${this.$route.params.version}`, query: { file: this.$route.query.filename, platform: 'Chrome' } })
            break
        }
      }

      fetch(url, {
        method: 'get'
      })
        .then(results => {
          return results.arrayBuffer()
        }).then(responseData => {
          return jszip.loadAsync(responseData)
        }).then(data => {
          var files = []
          Object.entries(data.files).forEach(([key, value]) => {
            if (!value.dir) {
              files.push(key)
            }
          })
          this.data = data
          this.files = files
        }).then(item => {
          this.setCurrentFile(this.$route.query.file)
        })
    },
    crxToZip (buf) {
      function calcLength (a, b, c, d) {
        let length = 0

        length += a
        length += b << 8
        length += c << 16
        length += d << 24
        return length
      }

      // 50 4b 03 04
      // This is actually a zip file
      if (buf[0] === 80 && buf[1] === 75 && buf[2] === 3 && buf[3] === 4) {
        return buf
      }

      // 43 72 32 34 (Cr24)
      if (buf[0] !== 67 || buf[1] !== 114 || buf[2] !== 50 || buf[3] !== 52) {
        throw new Error('Invalid header: Does not start with Cr24')
      }

      // 02 00 00 00
      if (buf[4] !== 2 || buf[5] || buf[6] || buf[7]) {
        throw new Error('Unexpected crx format version number.')
      }

      const publicKeyLength = calcLength(buf[8], buf[9], buf[10], buf[11])
      const signatureLength = calcLength(buf[12], buf[13], buf[14], buf[15])

      // 16 = Magic number (4), CRX format version (4), lengths (2x4)
      const zipStartOffset = 16 + publicKeyLength + signatureLength

      return buf.slice(zipStartOffset, buf.length)
    },
    getContentsFromName (filename) {
      if (this.data && this.data.files && filename) {
        console.log('HERE2')
        this.data.files[filename].async('nodebuffer').then(content => {
          console.log('HERE3')
          const string = new TextDecoder('utf-8').decode(content)
          if (this.shouldBeautify && (this.mode === 'javascript' || this.mode === 'jsx')) {
            this.fileContents = beautify(string, { indent_size: 2, space_in_empty_paren: true })
          } else {
            this.fileContents = string
          }
          this.$refs.myEditor.editor.setValue(this.fileContents)
        })
      }
    },
    setCurrentFile (filename) {
      this.loading = false
      this.currentFile = filename

      this.$router.push({ path: `/source/${this.$route.params.extension_id}/${this.$route.params.version}`, query: { file: filename, platform: this.$route.query.platform } })

      var fileArr = filename.split('.')

      var extension = fileArr[fileArr.length - 1]

      switch (extension) {
        case 'html':
        case 'htm':
        case 'shtml':
          this.mode = 'html'
          break
        case 'js':
          this.mode = 'javascript'
          break
        case 'json':
          this.mode = 'json'
          break
        case 'css':
          this.mode = 'css'
          break
        case 'jsx':
          this.mode = 'jsx'
          break
        default:
          this.mode = 'javascript'
          break
      }

      this.getContentsFromName(filename)
    }
  },
  mounted () {
    this.getExtension(this.$route.params.extension_id, this.$route.params.version)
  }
}
</script>
<style>
  body {
    overflow: hidden;
  }
  /* #footer {
    font-family: NHaasGroteskThin !important;
  } */
</style>
